import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import "./App.scss";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ThemeSelector from './ThemeSelector';

const stripePromise = loadStripe(window._env_.stripeKey);

ReactDOM.render(
	<React.StrictMode>
		<GoogleReCaptchaProvider reCaptchaKey={window._env_.recaptchaKey}>
			<Elements stripe={stripePromise}>
				<Router>
					<ThemeSelector>
						<App />
					</ThemeSelector>
				</Router>
			</Elements>
		</GoogleReCaptchaProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
