export const currency = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'symbol' });

export const getField = (form, fieldName) => {
	for (let block of form.Definition.blocks) {
		if (!block.fields)
			continue;
		for (let field of block.fields) {
			if (field.field == fieldName)
				return field;
		}
	}
	return null;
}

export const getFields = (form) => {
	let fields = [];
	for (let block of form.Definition.blocks) {
		if (!block.fields)
			continue;
		for (let field of block.fields) {
			fields.push(field);
		}
	}
	return fields;
}

export const loadDefaultValues = (form, initialValues) => {
	var formFields = getFields(form);
	for (var field of formFields) {
		if (field.defaultValue != undefined)
			initialValues[field.field] = field.defaultValue;
	}
}

export const getPaymentTotal = (form, values) => {
	var total = 0;

	for (let field of Object.keys(form.Schema.properties)) {
		var f = getField(form, field);
		if (f.options != null && f.costs != null) {
			if (f.type == "checkbox_list") {
				for (var v in values[field]) {
					var i = f.option_values?.indexOf(values[field][v]) ?? f.options?.indexOf(values[field][v]) ?? -1;
					if (i >= 0) {
						total += (f.costs[i] ?? 0);
					}
				}
			} else {
				var j = f.option_values?.indexOf(values[field]) ?? f.options?.indexOf(values[field]) ?? -1;
				if (j >= 0) {
					total += (f.costs[j] ?? 0);
				}
			}
		}
	}

	return currency.format(total);
}

export const isFormFieldVisible = (values, conditional, conditionalValue, conditionalValueNot) => {
	if (conditional === null)
		return true;

	if (conditionalValue != null && conditionalValue == "*" && values[conditional])
		return true;

	if (conditionalValue != null && values[conditional] === conditionalValue)
		return true;

	if (conditionalValueNot != null && values[conditional] !== conditionalValueNot)
		return true;

	return false;
}

export const updateFormFieldValue = (field, values, conditional, conditionalValue, conditionalValueNot, defaultValue) => {
	if (conditional && conditionalValue != null && values[conditional] !== conditionalValue)
		delete values[field];
	if (conditional && conditionalValue != null && values[field] == undefined)
		values[field] = defaultValue;
	if (conditional && conditionalValueNot != null && values[conditional] === conditionalValueNot)
		delete values[field];
	if (conditional && conditionalValueNot != null && values[field] == undefined)
		values[field] = defaultValue;
}

export const getSchemaConfig = (form) => {
	var config = { errMessages: { } };

	for (let block of form.Definition.blocks) {
		if (!block.fields)
			continue;
		for (let field of block.fields) {
			if (field.errorMessages) {
				config.errMessages = { ...config.errMessages, [field.field]: field.errorMessages }
			} else {
				config.errMessages = { ...config.errMessages, [field.field]: { "required": field.required ? `${field.description ?? field.label} is required` : null, "format": field.format ? `Not a valid email address` : null } }
			}
		}
	}

	return config;
}