import { setup } from 'axios-cache-adapter'

const api = setup({
	baseURL: window._env_.apiUrl,
	cache: {
		maxAge: 15 * 60 * 1000
	}
});

export const getFormGroups = () => {
	return api.get('/form-groups');
}

export const getFormByKey = (formKey) => {
	return api.get(`/form/${formKey}`);
}

export const getFormByShortCode = (code) => {
	return api.get(`/f/${code}`);
}

export const submitForm = (formKey, values, paymentData, recaptchaToken) => {
	return api.post('/submit', { form: formKey, formData: values, paymentData: paymentData, recaptcha: recaptchaToken })
}