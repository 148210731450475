import { useEffect, useState } from "react";
import { Switch, Route, Link, Redirect, useParams } from "react-router-dom";
import { getFormByShortCode } from "./Api";
import "survey-core/defaultV2.min.css";
import './App.css';
import logo from './images/logo.png';

import Index from "./pages/Index";
import FormPage from "./pages/Form";

function ShortCodeRedirect() {
	const params = useParams();
	const [formKey, setFormKey] = useState(null);

	useEffect(() => {
		getFormByShortCode(params.shortCode)
			.then(result => {
				if (result.data.exists == true)
					setFormKey(result.data.formKey);
				else 
					setFormKey('not-found');
			});
	}, [params.shortCode]);

	return <>
		{formKey &&
			<Redirect to={`/forms/${formKey}`} />
		}
	</>
}

function App() {
	const [appTitle, setAppTitle] = useState('');

	const loaded = () => {
		var height = document.getElementsByTagName("html")[0].scrollHeight;
		window.parent.postMessage(["setHeight", height], "*"); 
	}

	window.onload = loaded;

	const resizeObserver = new ResizeObserver(entries => {
		var height = entries[0].target.clientHeight;
		window.parent.postMessage(["setHeight", height], "*");
	});
  
	resizeObserver.observe(document.getElementsByTagName("body")[0]);

	useEffect(() => {
		setAppTitle(window._env_.title);
		document.title = window._env_.title;
	}, []);

	return <>
		<Switch>
			<Route path={"/f/:shortCode"} exact>
				<ShortCodeRedirect />
			</Route>
			<Route path={["/", "/forms/:formKey"]} exact>
				<div className="cover-container embedded-form1 d-flex w-100 h-100 p-3 mx-auto flex-column">
					<header className="mb-3">
						<Switch>
							<Route path="/forms/:formKey" exact>
								<Link to="/" exact="true" className="text-black" ><h3 className="float-start mb-0 fw-bold app-title">{appTitle}</h3></Link>
								<div className="float-end">
									<img src={logo} alt={appTitle} className="nav-logo" />
								</div>
							</Route>
						</Switch>
					</header>
				
					<main className="pb-5 text-start">
						<Switch>
							<Route path="/" exact component={Index} />
							<Route path="/forms/:formKey" exact component={FormPage} />
							<Route path="**">
								<Redirect to="/" />
							</Route>
						</Switch>
					</main>

					{/* <footer className="mt-auto text-black-50">
						<p>Return to <a href="https:www.muskokabible.com" className="text-black">Muskoka Bible Centre</a> website.</p>
					</footer> */}
				</div>
			</Route>

			<Route path="/embedform/:formKey" exact>
				<div className="embedded-form d-flex w-100 h-100 mx-auto flex-column">
					<main className="text-start">
						<Switch>
							<Route path="/embedform/:formKey" exact>
								<FormPage embedded={true} />
							</Route>
							<Route path="**">
								<Redirect to="/" />
							</Route>
						</Switch>
					</main>
				</div>
			</Route>

			<Route path="**">
				<Redirect to="/" />
			</Route>
		
		</Switch>
	</>
}

export default App;
