import { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';
import { BsCheckCircle, BsXCircle } from 'react-icons/bs';
import { RiQuestionnaireLine } from 'react-icons/ri';
import { FaWpforms } from 'react-icons/fa';
import { getFormByKey } from "../Api";
import DynamicForm from '../components/DynamicForm';
import SurveyJSForm from '../components/surveyjs/SurveyJSForm';

function FormPage({ embedded = false }) {
	const params = useParams();
	const history = useHistory();

	const [loading, setLoading] = useState(true);
	const [loadError, setLoadError] = useState(false);
	const [form, setForm] = useState();
	const [submitted, setSubmitted] = useState();
	const [submittedData, setSubmittedData] = useState();
	const [submitSuccess, setSubmitSuccess] = useState();
	const [submitError, setSubmitError] = useState();
	const [emailAddress, setEmailAddress] = useState();
	const [title, setTitle] = useState('Loading...');

	if (embedded) {
		document.body.style.backgroundImage = "none";
	}

	useEffect(() => {
		getFormByKey(params.formKey)
			.then(result => {
				setForm(result.data);
				setTitle(result.data.Title);
				setLoading(false);
			})
			.catch(error => {
				setLoadError(true);
			});
	}, [params.formKey]);

	const onSuccess = (value) => {
		setSubmitSuccess(value);
		if (value == true) {
			setSubmittedData(null);
		}
	}

	const onTryAgain = () => {
		setSubmittedData(null);
		setSubmitted(null);
		setSubmitError(null);
	}

	const onClose = () => {
		if (embedded) { 
			resetForm();
		} else {
			history.push("/");
		}
	}

	const resetForm = () => {
		setSubmitted(null);
		setSubmittedData(null);
		setSubmitSuccess(null);
	}

	const isAvailable = () => {
		if (form == undefined)
			return true;

		if (form.AvailableFrom && moment(form.AvailableFrom).isAfter(moment()))
			return false;

		if (form.AvailableTo && moment(form.AvailableTo).isBefore(moment()))
			return false;

		return true;
	}
	
	const isBeforeAvailableDates = () => {
		return form.AvailableFrom && moment(form.AvailableFrom).isAfter(moment());
	}

	const isAfterAvailableDates = () => {
		return form.AvailableTo && moment(form.AvailableTo).isBefore(moment())
	}

	const Card = ({ children }) => {
		return <div className="row row-cols-1 row-cols-md-2">
			<div className={classNames("card w-100 p-0 border-0", embedded ? "" : "shadow")}>
				{/* {!form && loading && !loadError &&
				<div style={{ height: '300px' }}></div>
				} */}
				{form && form.ImageURL &&
				<img className="card-image-top rounded-top w-100" src={form?.ImageURL ?? "" } alt="" style={{ height: '300px', maxHeight: '300px', objectFit: 'cover' }} />
				}
				{!loadError && 
				<div className={classNames("card-body form", embedded ? "p-4 p-sm-5 pt-sm-4 pb-sm-4 pt-3" : "p-sm-5 pt-sm-4 pb-sm-4 pt-3")}>
					<h1 className={classNames(form?.Definition?.titleClass ?? "display-5 fw-normal mb-4")}>{title}</h1>
					{children}
				</div>
				}
				{loadError && 
				<div className="text-center mt-5 mb-5">
					<BsXCircle size="100" color="red" className="mb-3" />
					<p className="fs-4">Unable to load form at this time. Please try again.</p>
				</div>
				}
			</div>
		</div>
	}

	return <> 
		<Card>
			{!form && !loading &&
				<div className="text-center">
					<RiQuestionnaireLine size="200" color="lightgray" className="mb-3" />
					<h4 className="fw-normal">The form you are trying to access can't be found</h4>
					<Link to={`/`} className="btn btn-lg btn-success mt-5">Return to Home</Link>
				</div>
			}
			{form && form.Disabled == true &&
				<div className="text-center">
					<FaWpforms size="200" color="lightgray" className="mb-3" />
					<h4 className="fw-normal">This form is currently not available.</h4>
				</div>
			}

			{form && !form.Disabled && isAvailable() == false &&
				<div className="text-center">
					<FaWpforms size="200" color="lightgray" className="mb-3" />
					{isBeforeAvailableDates() && 
						<h4 className="fw-normal">This form is not available until {moment(form.AvailableFrom).format('LL')}.</h4>
					}
					{isAfterAvailableDates() && 
						<h4 className="fw-normal">This form is currently not available.</h4>
					}
				</div>
			}

			{form && !form.Disabled && !submitted && isAvailable() &&
			 	<>
					{form.DefinitionType === 0 &&
					<DynamicForm form={form} data={submittedData} embedded={embedded} onSubmitted={setSubmitted} onSuccess={onSuccess} onError={setSubmitError} emailAddress={setEmailAddress} />
					}
					{form.DefinitionType === 1 && 
					<SurveyJSForm form={form} data={submittedData} embedded={embedded} onSubmitted={setSubmitted} onSuccess={onSuccess} onError={setSubmitError} emailAddress={setEmailAddress} />
					}
				</>
			}

			{submitSuccess && 
				<div className="text-center mt-4">
					<BsCheckCircle size="100" color="green" className="mb-3" />
					<p className="fw-bold">Form Successfully Submitted</p>

					{form.EmailConfirmation == true && emailAddress && 
					<div className="mb-3">
						An email confirmation has been sent to: <strong>{emailAddress}</strong>
					</div>
					}

					<button onClick={onClose} className="btn btn-lg btn-success w-50">{ embedded ? "Done" : "Close"}</button>
				</div>
			}

			{submitError &&
				<div className="text-center mt-4">
					<BsXCircle size="100" color="red" className="mb-3" />
					<p>Form Submission Failed</p>
					<div className="mb-3">
						<div className="fw-bold">The returned error message was:</div>
						{submitError}
					</div>
					<button className="btn btn-secondary text-white btn-lg" type="button" onClick={onTryAgain}>
						Try Again
					</button>
				</div>
			}
		</Card>

	</>
}

export default FormPage