import { useEffect } from 'react';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import classNames from 'classnames';
import { isFormFieldVisible, updateFormFieldValue } from '../Common';

function TextFormField({ field, label, alignTop = false, required = false, conditional = null, conditionalValue = null, conditionalValueNot = null, maxLength = null, xs = 12, sm = 6, md = null, lg = null }) {
	const { values, errors, touched, submitCount } = useFormikContext();

	useEffect(() => {
		updateFormFieldValue(field, values, conditional, conditionalValue, conditionalValueNot, "");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values]);

	return <>
		{isFormFieldVisible(values, conditional, conditionalValue, conditionalValueNot) &&
			<>
			<div className={classNames(`col-${xs}`, `col-sm-${sm}`, alignTop ? "" : "align-self-end", "mb-3 form-floating-x")}>
				<p className="fw-bold mb-1 small">{label}</p>
				<Field type="text" name={field} maxLength={maxLength} className={`form-control shadow-sm ${errors[field] && (touched[field] || submitCount > 0) && 'is-invalid'}`} placeholder={required ? "Required" : ""} autoComplete="off" />
				<ErrorMessage name={field} component="div" className="fw-bold small text-danger" />	
			</div>
			</>
		}
	</>
}

export default TextFormField