import { useFormikContext } from "formik";
import _ from 'lodash'
import { currency, getField, getPaymentTotal, isFormFieldVisible } from "../Common";

function TemplatedContentBlock({ content, form, classes = "fs-6", hr = false, insideGroup = false, conditional = null, conditionalValue = null, conditionalValueNot = null, xs = 12, sm = 12 }) {
	const { values } = useFormikContext();
	const createMarkup = (content) => { return { __html: content }; };

	const templateContent = () => {
		var templated = _.clone(content);
		for (let field of Object.keys(form.Schema.properties)) {
			let fieldValue = values[field];
			templated = templated.replace(`{${field}}`, fieldValue ?? "");

			// Replace calculated fields
			var f = getField(form, field);
			if (f.options != null && f.costs != null) {
				var i = f.option_values?.indexOf(values[field]) ?? f.options?.indexOf(values[field]) ?? -1;
				if (i >= 0) {
					templated = templated.replace(`{${field}-value}`, f.costs[i] != null ? currency.format(f.costs[i]) : "");
				}
			}
		}

		templated = templated.replace('{total}', getPaymentTotal(form, values));

		return templated;
	}

	return <>
		{isFormFieldVisible(values, conditional, conditionalValue, conditionalValueNot) &&
			<div className={ insideGroup ? "" : "row mb-3" }>
				<div className={`col-12 ${classes}`}>
					<div dangerouslySetInnerHTML={createMarkup(templateContent())}></div>
					{hr && <hr />}
				</div>
			</div>
		}
	</>
}

export default TemplatedContentBlock