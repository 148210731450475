import { useEffect } from 'react';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import classNames from 'classnames';
import { updateFormFieldValue } from '../Common';

function RadioListField({ field, options, option_values, label, vertical = false, alignTop = false, required = false, conditional = null, conditionalValue = null, conditionalValueNot = null, xs = 12, sm = 6, md = null, lg = null }) {
	const { values, errors, touched, submitCount } = useFormikContext();

	useEffect(() => {
		updateFormFieldValue(field, values, conditional, conditionalValue, conditionalValueNot, "");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values]);

	return <>
		{(conditional === null || (conditional && ((conditionalValue != null && values[conditional] === conditionalValue) || (conditionalValueNot != null && values[conditional] !== conditionalValueNot)))) &&
			<div className={classNames(`col-${xs}`, `col-sm-${sm}`, alignTop ? "" : "align-self-end", "mb-3 form-floating-x")}>
				<p className="fw-bold mb-1 small">{label}</p>
				<div role="group">
					{options && options.map((option, index) => {
						return <label key={index} className={classNames("p-1", vertical ? 'd-block' : 'd-inline-block')}>
							<Field type="radio" className={classNames("form-check-input shadow-sm", errors[field] && (touched[field] || submitCount > 0) && 'is-invalid')} name={field} value={option_values != null && option_values[index] != null ? option_values[index] : option} /> {option}
						</label> 
					})}
				</div>
				<ErrorMessage name={field} component="div" className="fw-bold small text-danger" />
			</div>
		}
	</>
}

export default RadioListField