import { useFormikContext } from 'formik';
import classNames from 'classnames';
import { isFormFieldVisible } from '../Common';

function ContentBlock({ content, classes = "fs-6", hr = false, insideGroup = false, conditional = null, conditionalValue = null, conditionalValueNot = null, xs = 12, sm = 12, md = null, lg = null  }) {
	const { values } = useFormikContext();

	const createMarkup = (content) => { return { __html: content }; };

	return <>
		{isFormFieldVisible(values, conditional, conditionalValue, conditionalValueNot) &&
			<div className={ insideGroup ? "" : "row mb-3" }>
				<div className={classNames(classes, `col-${xs}`, `col-sm-${sm}`)}>
					<div dangerouslySetInnerHTML={createMarkup(content)}></div>
					{hr && <hr />}
				</div>
			</div>
		}
	</>
}

export default ContentBlock