import { useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Survey } from "survey-react-ui";
import { StylesManager, Model } from "survey-core";
import { registerQuestionStripeModel, registerSurveyQuestionStripe } from "./SurveyQuestionStripe";
import { Elements, ElementsConsumer, CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { BsCheck, BsArrowRightShort } from 'react-icons/bs';
import { loadStripe } from '@stripe/stripe-js';
import classNames from "classnames";
import { submitForm } from "../../Api";
import { currency } from "../../Common";

const SurveyJSForm = ({ form, data, embedded, onSubmitted, onSuccess, onError, emailAddress }) => {
	const { executeRecaptcha } = useGoogleReCaptcha();
	const stripe = useStripe();
	const elements = useElements();
	
	StylesManager.applyTheme("defaultV2");
	
	// registerQuestionStripeModel();
	// registerSurveyQuestionStripe();
	
	const [survey, setSurvey] = useState();
	const [submitting, setSubmitting] = useState(false);
	const [paymentValid, setPaymentValid] = useState(!form?.RequiresPayment ?? true);
	const [paymentError, setPaymentError] = useState('Credit Card Information is not correct/valid');
	// const [paymentVisible, setPaymentVisible] = useState(true);
	const [paymentTotal, setPaymentTotal] = useState(0);
	const [submitCount, setSubmitCount] = useState(0);

	const isPaymentForm = () => {
		return form?.RequiresPayment ?? false;
	}

	useEffect(() => {
		let survey = new Model(form.Definition);
		survey.showCompletedPage = false;
		survey.questionErrorLocation = "bottom";
		survey.focusFirstQuestionAutomatic = false;
		survey.showNavigationButtons = false;

		survey.onCompleting.add((sender, options) => {
			console.log(sender.data);
			options.allow = false;
			// survey.completeText = "Submitting form...";
			survey.mode = "display";

			handleSubmit(sender.data)
				.then((result) => {
					// if (result == true)
					setSubmitting(false);
					if (result == false) {
						console.log('failed to submit')
						survey.mode = "edit";
					}
				});

			// setTimeout(() => {
			// 	// onSuccess(true);
			// 	// onSubmitted(true);
			// 	survey.mode = "edit";
			// 	setSubmitting(false);
			// }, 2000);
			setSubmitting(true);
		});
		survey.onComplete.add((sender, options) => {
			console.log(sender.data);
			// options.showDataSavingSuccess("Saving...");
			// onSuccess(true);
			// onSubmitted(true);
			// survey.start();
			// setSurvey(null);
			survey.currentPageNo = 0;
		});

		survey.onValueChanged.add((sender, options) => {
			let values = survey.getAllValues();
			setPaymentTotal(values.total ?? 0);
		});

		// survey.onGetTitleTagName.add((sender, options) => {
		// 	//For elements inside panel use <h4> instead of <h3> for elements in the page root.
		// 	const parent = options.element.parent;
		// 	// if(!!parent && parent.isPanel) {
		// 		// options.tagName = "h2";
		// 	// }
		// });
		
		setSurvey(survey);

		// console.log(executeRecaptcha)
	}, []);

	const handleManualSubmit = () => {
		setSubmitCount(submitCount+1);

		let isValid = survey.validate(true, true);
		if (!isValid) {
			return;
		}
		let values = survey.getAllValues();
		// setPaymentTotal(values.total);

		setSubmitting(true);

		handleSubmit(values)
			.finally(() => {
				setSubmitting(false);
			});
		// if (form.RequiresPayment) {
			
		// 	setPaymentVisible(true);
		// }
	}

	const handleSubmit = async (values) => {
		if (!executeRecaptcha) {
			console.log('recaptcha not loaded')
			// Recaptcha stripe has not loaded yet.
			return false;
		}

		var paymentData = null;

		if (form.RequiresPayment && paymentTotal > 0) {
			// Get a reference to a mounted CardElement. Elements knows how
			// to find your CardElement because there can only ever be one of
			// each type of element.
			const cardElement = elements.getElement(CardElement);

			// Use your card Element with other Stripe.js APIs
			const { error, paymentMethod } = await stripe.createPaymentMethod({
				type: 'card',
				card: cardElement,
				billing_details: {
					name: values.name ?? "",
					email: values.email ?? ""
				}
			});
			// console.log(paymentMethod)
			if (error) {
				// console.log(error)
				setPaymentError(error.message);
				setPaymentValid(false);
				// onError('There was an error while attempting to process your payment. Your information was not submitted.');
				// onSubmitted(true);
				return false;
			} 

			paymentData = paymentMethod;
		}

		const recaptchaToken = await executeRecaptcha('form');

		try {
			let result = await submitForm(form.FormKey, values, paymentData, recaptchaToken);

			onSuccess(result.data.success);

			if (result.data.success)
				emailAddress(result.data.email)
			else
				onError(result.data.error);

		} catch (error) {
			onError('There was an error while attempting to process your form submission. Your information was not submitted.');
		}

		onSubmitted(true);

		return true;
	}

	const cardChanged = (cardEvent) => {
		console.log(cardEvent);

		// const {stripe, elements} = this.props;
		
		if (cardEvent.complete === true && cardEvent.error === undefined) {

			// const cardElement = elements.getElement(CardElement);

			// // Use your card Element with other Stripe.js APIs
			// const { error, paymentMethod } = await stripe.createPaymentMethod({
			// 	type: 'card',
			// 	card: cardElement,
			// 	// billing_details: {
			// 	// 	name: //values.name ?? "",
			// 	// 	email: values.email ?? ""
			// 	// }
			// });


			// this.question.value = "complete";//paymentMethod.id;
			setPaymentValid(true);
			setPaymentError("");
		} else {
			// this.question.value = null;
			setPaymentValid(false);
			setPaymentError(cardEvent.error?.message)
		}
	}

	return <>
		{survey &&
		<Survey model={survey} />
		}
		
		{isPaymentForm() && paymentTotal > 0 && <>
		<div className="sd-root-modern">
			<div className="sd-row sd-clearfix sd-page__row">
			<div style={{ flex: "1 1 100%", minWidth: "auto", maxWidth: "100%" }}>
				<div className="sd-element sd-element--complex sd-element--with-frame sd-panel sd-row__panel" >

					<div className="sd-panel__header sd-element__header sd-element__header--location-top">
						<h4 className="sd-title sd-element__title sd-panel__title" aria-label="Your Contact" role="button">
							<span className="sv-string-viewer">Payment</span>
						</h4>
					</div>

					<div className="sd-panel__content">
					<h5 className="sd-element sd-title sd-element__title sd-question__title sd-question__title--required">
						<span>The total amount you will be charged is: </span>
						<span className="total">{currency.format(paymentTotal)}</span>
					</h5>

					<h5 className="sd-element sd-title sd-element__title sd-question__title sd-question__title--required">
						<span>Credit Card Information</span>
					</h5>

					<div className={classNames(["sd-input stripe-question", false ? "sd-input--error":""])} style={{ maxWidth: '400px' }}>
						<CardElement onChange={(e) => cardChanged(e)} stripe={stripe} elements={elements} />
					</div>
					<div className="sd-description" style={{ marginTop: '5px', fontSize: 'small' }}>Secure credit card processing is powered by <a href="https://www.stripe.com" target="_blank" noreferrer="true">Stripe</a></div>

					{!paymentValid && submitCount > 0 &&
					<div className="fw-bold small text-danger mt-3">
						{paymentError}
					</div>
					}
					</div>
				</div>

				</div>
			</div>
		</div>
		</>}

		{(!isPaymentForm() || paymentTotal === 0) && <>
			<button className="btn btn-lg btn-primary shadow d-none d-sm-block mt-4 mb-3" type="button" disabled={submitting} onClick={handleManualSubmit}>
				{!submitting ? 
					<><BsCheck /> Submit Form</> : 
					<><div className="spinner-border spinner-border-sm" /> Submitting...</>
				}
			{/* <button type="button" className="btn btn-lg btn-primary shadow d-none d-sm-block mt-4" onClick={handleManualSubmit}>Submit Form</button> */}
			</button>

			<button className="btn btn-lg btn-primary btn-block shadow d-block d-sm-none mt-4 mb-3" type="button" disabled={submitting} onClick={handleManualSubmit}>
			{!submitting ? 
				<><BsCheck /> Submit Form</> : 
				<><div className="spinner-border spinner-border-sm" /> Submitting...</>
			}
			{/* <button type="button" className="btn btn-lg btn-primary shadow d-none d-sm-block mt-4" onClick={handleManualSubmit}>Submit Form</button> */}
			</button>
		</>}
		{isPaymentForm() && paymentTotal > 0 && <>
			<button type="button" className="btn btn-lg btn-primary shadow d-none d-sm-block mt-4 mb-3" disabled={submitting} onClick={handleManualSubmit}>
				{!submitting ? 
					<><BsCheck /> Complete Purchase{ paymentTotal > 0 ? ` of ${currency.format(paymentTotal)}` : ""}</> : 
					<><div className="spinner-border spinner-border-sm" /> Submitting...</>
				}
			</button>
			<button type="button" className="btn btn-lg btn-primary btn-block shadow d-block d-sm-none mt-4 mb-3" disabled={submitting} onClick={handleManualSubmit}>
				{!submitting ? 
					<><BsCheck /> Complete Purchase{ paymentTotal > 0 ? ` of ${currency.format(paymentTotal)}` : ""}</> : 
					<><div className="spinner-border spinner-border-sm" /> Submitting...</>
				}
			</button>
		</>}

		{/* <div className="alert alert-danger mt-4 mb-4">
			Some Stripe payment error!
		</div> */}


	</>
}

export default SurveyJSForm