import { useState } from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import classNames from 'classnames';
import { BsLockFill } from 'react-icons/bs';
import '../css/stripe.css';
import { useFormikContext } from 'formik';

function StripeFormField({ field, label, alignTop = false, required = false, validChanged, xs = 12, sm = 6, md = null, lg = null }) {
	const { submitCount } = useFormikContext();
	const [isValid, setIsValid] = useState();

	const cardChanged = (card) => {
		var valid = card.complete === true && card.error === undefined;
		setIsValid(valid);
		validChanged(card, valid);
	}

	const touched = () => {
		if (isValid == undefined) {
			setIsValid(false);
			validChanged(null, false);
		}
	}

	return <>
		<div className={classNames(`col-${xs}`, `col-sm-${sm}`, alignTop ? "" : "align-self-end", "mb-3 form-floating-x")}>
			<p className="fw-bold mb-1 small">{label}</p>
			<CardElement onBlur={touched} onChange={cardChanged} className={classNames('StripeElement', isValid == false ? 'invalid' : '')} />
			{!isValid && submitCount > 0 &&
				<div className="fw-bold small text-danger">{label} is required or invalid</div>
			}
			<p className="small m-0"><BsLockFill /> Secure credit card processing is powered by <a href="https://www.stripe.com" target="_blank" rel="noreferrer">Stripe</a></p>
		</div>
	</>
}

export default StripeFormField