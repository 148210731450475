function FormFieldGroup({ children, title, titleClass = "fs-4", subtitle = null, separator = false }) {

	return <>
		{separator && 
		<hr />
		}
		{(title || subtitle) && 
		<div className="row mb-3">
			<div className="col-12">
				<div className={titleClass}>{title}</div>
				{subtitle &&
				<span>{subtitle}</span>
				}
			</div>
		</div>
		}
		<div className="row mb-3">
			{children}
		</div>
	</>
}

export default FormFieldGroup