import { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom"
import moment from "moment";
import { getFormGroups } from "../Api";
import logo from '../images/logo.png';

function Index() {
	const [appTitle, setAppTitle] = useState('');
	const [formGroups, setFormGroups] = useState();
	const [error, setError] = useState();

	useEffect(() => {
		setAppTitle(window._env_.title);

		getFormGroups()
			.then(result => {
				setFormGroups(result.data);
			})
			.catch(error => {
				setError('Unable to load forms at this time. Please try again.');
			});
	 }, []);

	 const isAvailable = (form) => {
		if (form == undefined)
			return true;

		if (form.AvailableFrom && moment(form.AvailableFrom).isAfter(moment()))
			return false;

		if (form.AvailableTo && moment(form.AvailableTo).isBefore(moment()))
			return false;

		return true;
	}

	const Card = ({ form }) => <div className="card border-0 shadow">
		<Link to={isAvailable(form) ? `/forms/${form.FormKey}` : `/forms/${form.FormKey}`}>
		{form && form.ImageURL &&
		<img className={`card-img-top ${isAvailable(form) ? '' : 'not-available'}`} src={form.ImageURL} alt={form.Title} style={{ height: '200px', objectFit: 'cover' }} />
		}
		{form && form.ImageURL == undefined &&
		<div style={{ backgroundColor: 'lightgray', height: '200px', borderTopLeftRadius: 'calc(0.25rem - 1px)', borderTopRightRadius: 'calc(0.25rem - 1px)' }} />
		}
		{isAvailable(form) == false && 
		<div className="card-img-overlay text-center">
			<h4 className="card-title fw-bold mt-5">Not Available</h4>
			<p className="card-text">This form is currently not available.</p>
		</div>
		}
		<div className="card-body pb-2 pt-3 text-start">
			<h5 className={`fw-bold ${isAvailable(form) == false ? 'text-muted' : ''}`}>{form.Title}</h5>
		</div>
		</Link>
	</div>

	return <>
		<div className="pricing-header p-3 pb-md-4 mx-auto text-center">
			<img src={logo} alt={appTitle} size="100" className="logo" />
			<h1 className="display-5 fw-normal">Welcome to <span className="text-nowrap">{appTitle}</span></h1>
			<p className="fs-5">Complete a form online quickly and easily. Simply choose a form below:</p>
			
			{error && 
			<p className="lead">
				<div className="text-danger fw-bold">{error}</div>
			</p>
			}
		</div>

		{formGroups && formGroups.map((formGroup, index) => {
			return <Fragment key={index}>
				<h3 className={formGroup.Forms.length > 0 ? "mb-4" : ""}>{formGroup.Title}</h3>
				<div className="form-collection row mb-5">
					{formGroup.Forms.length === 0 && 
						<div className="">There are currently no online forms available.</div>
					}
					{formGroup.Forms.map((form, index) => {
						return <div key={index} className="col-12 col-sm-6 mb-4">
							<Card form={form} />
						</div>
					})}
				</div>
			</Fragment>
		})}
		
	</>
}

export default Index