import { useEffect } from 'react';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import classNames from 'classnames';
import { updateFormFieldValue } from '../Common';

function CheckBoxField({ field, label, checkboxLabel, alignTop = false, required = false, conditional = null, conditionalValue = null, conditionalValueNot = null, xs = 12, sm = 6, md = null, lg = null }) {
	const { values, errors, touched, submitCount } = useFormikContext();

	useEffect(() => {
		updateFormFieldValue(field, values, conditional, conditionalValue, conditionalValueNot, false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values]);

	return <>
		{(conditional === null || (conditional && ((conditionalValue != null && values[conditional] === conditionalValue) || (conditionalValueNot != null && values[conditional] !== conditionalValueNot)))) &&
			<div className={classNames(`col-${xs}`, `col-sm-${sm}`, alignTop ? "" : "align-self-end", "mb-3 form-floating-x")}>
				{label &&
					<p className="fw-bold mb-1 small">{label}</p>
				}
				<label className="p-1">
					<Field type="checkbox" className={classNames("form-check-input shadow-sm", errors[field] && (touched[field || submitCount > 0]) && 'is-invalid')} name={field} /> {checkboxLabel}
				</label> 
				<ErrorMessage name={field} component="div" className="fw-bold small text-danger" />
			</div>
		}
	</>
}

export default CheckBoxField