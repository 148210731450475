import { useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { BsCheck } from 'react-icons/bs';
import classNames from 'classnames';
import { submitForm } from "../Api";
import { getPaymentTotal, getSchemaConfig, loadDefaultValues } from '../Common';
import FormFieldGroup from "./FormFieldGroup";
import ContentBlock from "./ContentBlock";
import TemplatedContentBlock from './TemplatedContentBlock';
import TextFormField from "./TextFormField";
import TextAreaFormField from './TextareaFormField';
import SelectFormField from "./SelectFormField";
import CheckBoxField from './CheckBoxFormField';
import CheckBoxListField from './CheckBoxListFormField';
import RadioListField from './RadioListFormField';
import DatePickerFormField from './DatePickerFormField';
import StripeFormField from './StripeFormField';

const { buildYup } = require("schema-to-yup");

function DynamicForm({ form, data, embedded, onSubmitted, onSuccess, onError, emailAddress }) {

	const stripe = useStripe();
	const elements = useElements();

	const { executeRecaptcha } = useGoogleReCaptcha();
	
	const emptySchema = { "type": "object", "properties": {} };
	const schema = buildYup(form.Schema.$id ? form.Schema : emptySchema, getSchemaConfig(form));

	const defaultFieldValues = { 'string': '', 'number': '', 'array': [], 'boolean': false };
	const initialValues = data ?? Object.fromEntries(Array.from(Object.keys(schema.fields), key => ([key, defaultFieldValues[schema.fields[key]['_type']]]) ));
	
	loadDefaultValues(form, initialValues);

	const [paymentValid, setPaymentValid] = useState(!form?.RequiresPayment ?? true);
	const [paymentError, setPaymentError] = useState('Credit Card Information is not correct/valid');

	const paymentValidChanged = (card, valid) => {
		setPaymentError('Credit Card Information is not correct/valid');
		setPaymentValid(valid);
	}

	const isPaymentForm = () => {
		return form?.RequiresPayment ?? false;
	}

	const handleSubmit = async (values) => {
		if (!executeRecaptcha) {
			// Recaptcha stripe has not loaded yet.
			return;
		}

		// console.log(values);
		// return;

		var paymentData = null;

		if (form.RequiresPayment) {
			// Get a reference to a mounted CardElement. Elements knows how
			// to find your CardElement because there can only ever be one of
			// each type of element.
			const cardElement = elements.getElement(CardElement);

			// Use your card Element with other Stripe.js APIs
			const { error, paymentMethod } = await stripe.createPaymentMethod({
				type: 'card',
				card: cardElement,
				billing_details: {
					name: values.name ?? "",
					email: values.email ?? ""
				}
			});
			// console.log(paymentMethod)
			if (error) {
				// console.log(error)
				setPaymentError(error.message);
				setPaymentValid(false);
				// onError('There was an error while attempting to process your payment. Your information was not submitted.');
				// onSubmitted(true);
				return;
			} 

			paymentData = paymentMethod;
		}

		const recaptchaToken = await executeRecaptcha('form');

		try {
			let result = await submitForm(form.FormKey, values, paymentData, recaptchaToken);

			onSuccess(result.data.success);

			if (result.data.success)
				emailAddress(result.data.email)
			else
				onError(result.data.error);

		} catch (error) {
			onError('There was an error while attempting to process your form submission. Your information was not submitted.');
		}

		onSubmitted(true);
	}

	const formBlocks = form.Definition?.blocks?.map((block, index) => {
		switch (block.type) {
			case "content":
				return <ContentBlock key={index} {...block} />
			case "template_content":
				return <TemplatedContentBlock key={index} form={form} {...block} />
			case "formfieldgroup":
				return <FormFieldGroup key={index} {...block}>
					{block.fields.map((field, index2) => {
						switch (field.type) {
							case "content":
								return <ContentBlock key={index2} {...field} insideGroup={true} />
							case "template_content":
								return <TemplatedContentBlock key={index2} form={form} {...field} insideGroup={true} />
							case "text":
								return <TextFormField key={index2} {...field} />
							case "textarea":
								return <TextAreaFormField key={index2} {...field} />
							case "select":
								return <SelectFormField key={index2} {...field} />
							case "checkbox":
								return <CheckBoxField key={index2} {...field} />
							case "checkbox_list":
								return <CheckBoxListField key={index2} {...field} />
							case "radio_list":
								return <RadioListField key={index2} {...field} />
							case "date":
								return <DatePickerFormField key={index2} {...field} />
							case "stripe":
								return <StripeFormField key={index2} {...field} validChanged={paymentValidChanged} />
							default:
								return null
						}
					})}
				</FormFieldGroup>
			default:
				return block.type
		}
	});

	return <div className="classic-form">
		<Formik
			validationSchema={schema}
			initialValues={initialValues}
			onSubmit={(values, { setSubmitting }) => {
				if (!paymentValid) {
					setSubmitting(false);
					return;
				}
				handleSubmit(values)
					.finally(() => {
						setSubmitting(false);
					});
			}}
		>
			{({ isSubmitting, submitCount, isValid, values, errors }) => <>
				<fieldset disabled={isSubmitting}>
					<Form>

						{formBlocks}

						{(!isValid || (!paymentValid && submitCount > 0)) &&
							<div className="alert alert-danger pb-0 mt-4 mb-4">
								<p>Please complete all required fields before trying to submit the form.</p>
								<ul>
								{Object.keys(errors).map((errorField, index) => {
									return <ErrorMessage key={index} name={errorField} component="li" />	
								})}
								{!paymentValid &&
									<li>{paymentError}</li>
								}
								</ul>
							</div>
						}

{/* || !isValid || !paymentValid */}
						<button className="btn btn-lg btn-primary shadow d-none d-sm-block" type="submit" disabled={isSubmitting || ((!isValid || !paymentValid) && submitCount > 0)}>
						{/*  */}
							{!isSubmitting ? 
								<><BsCheck /> { isPaymentForm() ? `Complete Purchase of ${getPaymentTotal(form, values)}` : 'Submit Form' }</>  : 
								<><div className="spinner-border spinner-border-sm" /> Submitting...</>
							}
						</button>

						<button className={classNames("btn btn-lg btn-primary shadow w-100 d-block d-sm-none", embedded ? "mb-5" : "")} type="submit" disabled={isSubmitting || ((!isValid || !paymentValid) && submitCount > 0)}>
						{/*  */}
							{!isSubmitting ? 
								<><BsCheck /> { isPaymentForm() ? `Complete Purchase of ${getPaymentTotal(form, values)}` : 'Submit Form' }</>  : 
								<><div className="spinner-border spinner-border-sm" /> Submitting...</>
							}
						</button>

					</Form>
				</fieldset>
			</>}

		</Formik>
	</div>
}

export default DynamicForm;