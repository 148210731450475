import React from 'react';

const ThemeMBC = React.lazy(() => import('./themes/ThemeMBC'));
const ThemeFHM = React.lazy(() => import('./themes/ThemeFHM'));

const ThemeSelector = ({ children }) => {
	const ORG = window._env_.org;
	return (
		<>
			<React.Suspense fallback={<></>}>
				{(ORG === 'MBC') && <ThemeMBC />}
				{(ORG === 'FHM') && <ThemeFHM />}
			</React.Suspense>
			{children}
		</>
	)
}

export default ThemeSelector;